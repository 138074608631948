/*
 *   File : profile.js
 *   Author : https://evoqins.com
 *   Description : Profile summary
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react"
import { Modal } from "bootstrap";

// Custom compoents
import { CustomSegmentChart } from "../../../../Components/Chart";
import { DataTableContainer } from "../../../../Components/DataTable";
import { RiskPortfolioHistoryModal } from "../../../../Components/Modal";
import { LinkButton } from "../../../../Components/Buttons";
import { EmptyScreen } from "../../../../Components/Others";

// Custom styles
import Colors from '../../../../Styles/color.module.scss';

// import services
import APIService from "../../../../Services/api-service";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};

const RiskProfiling = (props) => {

    const [showRiskModal, setShowRiskModal] = useState(false);
    const [riskData, setRiskData] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        _getRiskProfile();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showRiskModal === true) {
            const modal = new Modal(document.getElementById("risk-profile-history"), {});
            modal.show();
        }
    }, [showRiskModal]);


    function _handleRiskModal(status) {
        setShowRiskModal(status);
    }

    // API - risk profile
    const _getRiskProfile = () => {
        const url = "/user/get-risk-assessment";
        const request = {
            user_id: props.id,
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setRiskData(response.data);
            }
            else {
                setRiskData({});
            }
            setLoader(false);
        })
    }

    // Table columns
    const TABLE_COLUMNS = [
        {
            name: "Question",
            cell: row => row.question,
            width: '40%'
        },
        {
            name: "Previous answer",
            cell: row => row.previous_answer,
            width: "200px"
        },
        {
            name: "New answer ",
            cell: row => row.new_answer,
            width: "150px"
        },
        {
            name: "Edit date",
            cell: row => row.edited
        }
    ]

    return (
        loader === true ?
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
            :
            Object.keys(riskData).length === 0 ?
                <div className="border-radius-32px bg-white padding-40px-tb">
                    <EmptyScreen className="my-5"
                        title="Risk profile not completed"
                        image={require("../../../../Assets/Images/loader/empty-funds.png")}
                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                </div>
                :
                <Fragment>
                    <div className="border-radius-32px bg-white padding-70px-bottom">
                        <div className="padding-32px-all">
                            <div className="row">
                                <div className="col-12 pb-4">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0">Risk score
                                            {
                                                riskData.version_history !== null &&
                                                <span className="e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    (Last updated on: {riskData.version_history.at(-1).created})
                                                </span>
                                            }
                                        </h4>
                                        {
                                            riskData.version_history !== null &&
                                            <LinkButton text="View previous risk profile"
                                                className="e-font-14 e-line-height-24"
                                                hide_arrow={true}
                                                handleClick={() => _handleRiskModal(true)} />
                                        }
                                    </div>
                                </div>
                                {/* Chart */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="e-bg-magnolia e-box-shadow-chart p-4 border-radius-24px position-relative e-risk-container">
                                        <CustomSegmentChart value={riskData.risk_score} />
                                        <div className="position-absolute top-30per start-50 translate-x-40">
                                            <span className="e-font-12 e-line-height-18 e-montserrat-regular color-black-olive" >
                                                Risk score
                                            </span>
                                            <h6 className="e-montserrat-semi-bold color-dark-jungle-green e-font-30 e-line-height-38 text-center mb-0" >{riskData.risk_score}</h6>
                                        </div>
                                        <h6 className="margin-44px-top color-dark-charcoal e-font-16 e-line-height-24 letter-spacing-025px e-montserrat-semi-bold mb-1 text-center">{riskData.investment_style_text}</h6>
                                    </div>
                                </div>

                                {/* Risk questionnaire details */}
                                <div className="col-12 padding-32px-top">
                                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4">Risk profile question & answers</h4>
                                    {
                                        riskData.risk_questions.map((item, key) =>

                                            <div className="mt-3"
                                                key={key}>
                                                <h5 className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-3">
                                                    {item.topic}
                                                </h5>
                                                <div className="e-bg-ethereal-lavender border-radius-8px ">
                                                    {
                                                        item.assessment.map((question, index) =>
                                                            <div className="py-3 padding-32px-lr"
                                                                key={index}>
                                                                <div className="row d-flex align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <span className="e-font-14 e-line-height-24 e-montserrat-regular color-dark-charcoal">
                                                                            {question.question}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <span className="e-font-14 e-line-height-24 e-montserrat-medium color-dark-charcoal">
                                                                            {question.answer}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Risk assessment edit log */}
                        {
                            riskData.edit_log !== null &&
                            <div className="padding-10px-top">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">Risk assessment edit log</h4>
                                <DataTableContainer data={riskData.edit_log}
                                    columns={TABLE_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                            </div>
                        }
                    </div>
                    {
                        showRiskModal && <RiskPortfolioHistoryModal versionData={riskData.version_history}
                            closeModal={() => _handleRiskModal(false)} />
                    }
                </Fragment>

    )
}

export default RiskProfiling