import { useEffect, useState } from "react";
import Colors from "../Styles/color.module.scss";
import { format, parse } from "date-fns";

function useClickOutside(ref, callback) {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

function _forceNavigate(callback) {
    useEffect(() => {

        const handleBackButton = (event) => {
            event.preventDefault(); // Prevent the default browser back action
            // Navigate to a specific route
            callback(); // Replace '/your-route' with the desired route
        };

        window.onpopstate = handleBackButton;

        return () => {
            window.onpopstate = null; // Cleanup the event listener
        };
        //eslint-disable-next-line
    }, []);
}

const useCountdown = (initialSeconds, interval = 1000) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds((prevSeconds) => prevSeconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, interval);

        return () => clearInterval(countdown);
    }, [seconds, interval]);

    const resetCountdown = () => {
        setSeconds(initialSeconds);
    };

    return { seconds, resetCountdown };
};


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer"
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const _emailValidator = (email) => {
    let valid = true
    // eslint-disable-next-line
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!email_regex.test(email)) {
        valid = false;
    }
    return valid
}

const _logout = () => {
    sessionStorage.removeItem('mintit_access_token');
    window.location.href = "/";
}

const _formatDate = (date) => {
    if (date === "DD-MM-YYYY") {
        return "DD-MM-YYYY"
    } else {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        const start_date = new Date(date);
        const formatted_date = start_date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
        return formatted_date
    }
}

const _formatTime = (time) => {
    const date = parse(time, 'HH:mm:ss', new Date());
    return format(date, 'hh:mm a');

}


const _getFileName = (url) => {
    // Split the URL by '/'
    const parts = url.split('/');

    // Get the last part of the URL (including query parameters)
    const lastPart = parts.pop();

    // Split the last part by '?' to remove query parameters and get the file name
    const fileName = lastPart.split('?')[0];
    return fileName
}


export {
    useClickOutside,
    _forceNavigate,
    TABLE_STYLE,
    _emailValidator,
    _logout,
    useCountdown,
    _formatDate,
    _formatTime,
    _getFileName,
}