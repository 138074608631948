/*
 *   File : manage-custom-portfolio.js
 *   Author : https://evoqins.com
 *   Description : Container screen to create a new custom model portfolio in the application.
 *   Version : 1.0.0
*/
import { Fragment, useEffect, useRef, useState } from "react"
// import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { CustomCheckBox, CustomTextInput1, PercentageInput, SearchSelect } from "../../../../Components/FormElements";
import { Icon } from "../../../../Components/Icon";
import { Breadcrumb } from "../../../../Components/Others";
import { LinkButton, PrimaryButton, SecondaryButton } from "../../../../Components/Buttons";
// import { CustomTabBar } from "../../../../Components/Tab";
// import { DeleteConfirmationModal } from "../../../../Components/Modal";

import APIService from "../../../../Services/api-service";

// const BASKET_OPTIONS = [
//     {
//         label: "Active basket",
//         id: 1,
//         short_name: "active"
//     },
//     {
//         label: "Passive basket",
//         id: 2,
//         short_name: "passive"
//     }
// ]


const ManageCustomPortfolio = () => {
    const basketRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const [apiLoader, setApiLoader] = useState(false);
    // const [assetClass, setAssetClass] = useState("");
    // const [passiveAssetClass, setPassiveAssetClass] = useState("");
    // const [assetClassError, setAssetClassError] = useState("");
    // eslint-disable-next-line
    const [basketType, setBasketType] = useState(location.state.basket);
    // const [confirmFunds, setConfirmFund] = useState(false);

    const [fundList, setFundList] = useState([{
        id: 1,
        asset_class: "",
        allocation: "",
        error: ["", ""],
        funds: [{
            fund: "",
            fund_id: null,
            allocation: "",
            error: ["", ""],
            allocation_id: null,
            primary_fund: false,
            basket_type: "ACTIVE",
            asset_class: "",
        }],

    }]);
    const [funds, setFunds] = useState([]);

    const [passiveList, setPassiveList] = useState([{
        id: 1,
        allocation: "",
        asset_class: "",
        error: ["", ""],
        funds: [{
            fund: "",
            fund_id: null,
            allocation: "",
            error: ["", ""],
            allocation_id: null,
            primary_fund: false,
            basket_type: "ACTIVE",
            asset_class: "",
        }],

    }]);

    useEffect(() => {
        if (location.state.portfolio !== null) {
            let active_list = []
            let passive_list = []

            if (location.state.portfolio[`active`] !== null) {
                active_list = Object.keys(location.state.portfolio[`active`]).map((assetClass, index) => ({
                    asset_class: assetClass,
                    id: index + 1,
                    error: ['', ''],
                    allocation: location.state.portfolio[`active`][assetClass][0].allocation,
                    funds: location.state.portfolio[`active`][assetClass].map(fund => ({
                        fund: fund.fund_name,
                        fund_id: fund.fund_id,
                        allocation: fund.allocation,
                        primary_fund: fund.primary_fund,
                        allocation_id: fund.allocation_id,
                        error: ["", ""],
                        asset_class: assetClass,
                        basket_type: 'ACTIVE'
                    }))
                }));
                // setAssetClass(active_list[0].asset_class)
                setFundList(active_list);
            }
            if (location.state.portfolio[`passive`] !== null) {
                passive_list = Object.keys(location.state.portfolio[`passive`]).map((assetClass, index) => ({
                    asset_class: assetClass,
                    id: index + 1,
                    allocation: location.state.portfolio[`passive`][assetClass][0].allocation,
                    error: ["", ""],
                    funds: location.state.portfolio[`passive`][assetClass].map(fund => ({
                        fund: fund.fund_name,
                        fund_id: fund.fund_id,
                        allocation: fund.allocation,
                        primary_fund: fund.primary_fund,
                        allocation_id: fund.allocation_id,
                        error: ["", ""],
                        asset_class: assetClass,
                        basket_type: 'PASSIVE'
                    }))
                }));

                if (passive_list.length > 0) {
                    setPassiveList(passive_list);
                }
            }
        }
    }, [location.state])


    useEffect(() => {
        _getFundList(null);
    }, []);

    // useEffect(() => {
    //     if (confirmFunds === true) {
    //         const modal = new Modal(document.getElementById("delete-modal"));
    //         modal.show()
    //     }
    // }, [confirmFunds]);

    // function _handleAssetClass(input_value) {
    //     if (basketType === 1) {
    //         setAssetClass(input_value);
    //     } else {
    //         setPassiveAssetClass(input_value);
    //     }
    //     setAssetClassError("");
    // }


    // function _handleBasket(index) {
    //     setBasketType(index)
    // }

    function _handleFundValue(input_value, index, error_index, key, fund_id) {
        if (basketType === 1) {
            const fund_types = [...fundList]
            const fund_list = [...fund_types[index].funds]
            fund_list[key]['fund'] = input_value
            fund_list[key]['fund_id'] = fund_id
            fund_list[key]['error'][error_index] = ""
            fund_types[index].funds = fund_list
            setFundList(fund_types);
        } else {
            const fund_types = [...passiveList]
            const fund_list = [...fund_types[index].funds]
            fund_list[key]['fund'] = input_value
            fund_list[key]['fund_id'] = fund_id
            fund_list[key]['error'][error_index] = ""
            fund_types[index].funds = fund_list
            setPassiveList(fund_types);
        }
    }

    function _handleNavigate() {
        navigate(-1);
    }

    function _handlePrimaryFund(check, index, key) {
        if (basketType === 1) {
            const fund_types = [...fundList]
            const fund_list = [...fund_types[index].funds];


            for (let i = 0; i < fund_list.length; i++) {
                if (i === key) {
                    fund_list[key]['primary_fund'] = check;
                } else {
                    fund_list[i]['primary_fund'] = false
                }

            }

            setFundList(fund_types);
        } else {
            const fund_types = [...passiveList]
            const fund_list = [...fund_types[index].funds];


            for (let i = 0; i < fund_list.length; i++) {
                if (i === key) {
                    fund_list[key]['primary_fund'] = check;
                } else {
                    fund_list[i]['primary_fund'] = false
                }

            }

            setPassiveList(fund_types);
        }
    }

    function _handleAllocation(input_value, index, error_index) {
        if (basketType === 1) {
            const fund_types = [...fundList]
            fund_types[index]['allocation'] = input_value
            fund_types[index].error[error_index] = ""
            setFundList(fund_types);

        } else {
            const fund_types = [...passiveList]
            fund_types[index]['allocation'] = input_value
            fund_types[index].error[error_index] = ""
            setPassiveList(fund_types);
        }
    }

    // function _handleConfirmation(status) {
    //     setConfirmFund(status)
    // }


    // handle fund type
    function _handleFundType(input_value, index, error_index) {
        if (basketType === 1) {
            const fund_types = [...fundList]
            fund_types[index]['asset_class'] = input_value
            fund_types[index].error[error_index] = ""
            setFundList(fund_types);
        } else {
            const fund_types = [...passiveList]
            fund_types[index]['asset_class'] = input_value
            fund_types[index].error[error_index] = ""
            setPassiveList(fund_types);
        }
    }

    // function _confirm() {
    //     return true
    // }

    function _handleDeleteFund(index, secondary_index, is_secondary) {
        if (basketType === 1) {
            let fund_types = [...fundList]
            if (is_secondary) {
                fund_types[index].funds.splice(secondary_index, 1);
            } else {
                fund_types.splice(index, 1);
            }
            setFundList(fund_types);
        } else {
            let fund_types = [...passiveList]
            if (is_secondary) {
                fund_types[index].funds.splice(secondary_index, 1);
            } else {
                fund_types.splice(index, 1);
            }
            setPassiveList(fund_types);
        }
    }

    function _handleCreatePortfolio() {
        let valid = true;
        const funds_array = basketType === 1 ? [...fundList] : [...passiveList];
        // const asset_class = basketType === 1 ? assetClass : passiveAssetClass

        for (let i = 0; i < funds_array.length; i++) {
            const fund_list = [...funds_array[i].funds];
            if (funds_array[i]["asset_class"] === "") {
                basketRef.current.scrollIntoView({ block: 'start' });
                funds_array[i].error[0] = "Fund type is required";
                valid = false;
            }
            if (funds_array[i]["allocation"] === "") {
                basketRef.current.scrollIntoView({ block: 'start' });
                funds_array[i].error[1] = "Allocation is required";
                valid = false;
            }

            // Count occurrences of each asset_class

            // for (let j = 0; j < fund_list.length; j++) {
            //     if (fund_list[j]["fund_id"] !== null) {
            //         if (!fund_list.some(item => item.primary_fund === true)) {
            //             if (j === fund_list.length - 1) {
            //                 basketRef.current.scrollIntoView({ block: 'start' });
            //                 toast.dismiss()
            //                 toast.error("Atleast one primary fund is required", {
            //                     type: 'error'
            //                 });
            //                 valid = false;
            //             }
            //         }
            //     }
            //     if (fund_list[j]["fund_id"] === null) {
            //         basketRef.current.scrollIntoView({ block: 'start' });
            //         fund_list[j]["error"][0] = "Select a fund";
            //         valid = false;
            //     }

            // }
        }
        const assetClassCounts = funds_array.reduce((counts, item) => {
            counts[item.asset_class] = (counts[item.asset_class] || 0) + 1;
            return counts;
        }, {});


        // Check if any asset_class is repeated more than once
        const hasDuplicates = Object.values(assetClassCounts).some(count => count > 1);
        if (hasDuplicates) {
            valid = false;
            toast.dismiss()
            toast.error("Asset class cannot be repeated", {
                type: 'error'
            });
        }
        if (valid === true) {
            _createCustomPortfolio(true)
        }
        if (basketType === 1) {
            setFundList(funds_array)
        } else {
            setPassiveList(funds_array)
        }
    }

    function _handleAddSecondaryFund(index) {
        if (basketType === 1) {
            const fund_types = [...fundList]
            const fund_list = [...fund_types[index].funds]
            fund_list.push({

                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                primary_fund: false,
                allocation_id: null,

            });

            fund_types[index].funds = fund_list
            setFundList(fund_types);
        } else {
            const fund_types = [...passiveList]
            const fund_list = [...fund_types[index].funds]
            fund_list.push({

                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                primary_fund: false,
                allocation_id: null,

            });

            fund_types[index].funds = fund_list
            setPassiveList(fund_types);
        }
    }

    // handle add fund
    function _handleAddFund() {
        if (basketType === 1) {
            const fund_types = [...fundList]
            fund_types.push({
                id: fund_types.length + 1,
                asset_class: "",
                allocation: "",
                error: ["", ""],
                funds: [{
                    fund: "",
                    fund_id: null,
                    allocation: "",
                    error: ["", ""],
                    allocation_id: null,
                    primary_fund: false,
                    asset_class: null,
                    basket_type: "ACTIVE"
                }],

            });
            setFundList(fund_types);
        } else {
            const fund_types = [...passiveList]
            fund_types.push({
                id: fund_types.length + 1,
                asset_class: "",
                allocation: "",
                error: ["", ""],
                funds: [{
                    fund: "",
                    fund_id: null,
                    allocation: "",
                    error: ["", ""],
                    allocation_id: null,
                    primary_fund: false,
                    asset_class: null,
                    basket_type: "PASSIVE"
                }],

            });
            setPassiveList(fund_types);
        }
    }

    // API - get funds
    const _getFundList = (search) => {
        const url = "/model-portfolio/get-fund";

        const request = {
            query: search
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                const fund_list = response.data.map((item) => {
                    return ({
                        name: item.label,
                        id: item.value
                    })
                })
                setFunds(fund_list)
            } else {
                setFunds([]);
            }
        })
    }

    // API - Create custom portfolio
    const _createCustomPortfolio = (validate) => {

        let active_funds_exist = fundList.filter((item) => item.funds.some((fund) => fund.fund_id !== null))

        let passive_funds_exist = passiveList.filter((item) => item.funds.some((fund) => fund.fund_id !== null))
        if (active_funds_exist.length === 0) {

            setApiLoader(false);
        }
        if (passive_funds_exist.length === 0) {
            setApiLoader(false);

        }
        const url = "/custom-model-portfolio/upsert";

        const active_list = fundList.flatMap((fund) => {
            return fund.funds.map((item) => ({
                "fund_id": item.fund_id,
                "basket_type": 'ACTIVE',
                "allocation_id": item.allocation_id,
                "allocation": parseFloat(fund.allocation),
                "asset_class": fund.asset_class,
                "primary_fund": item.primary_fund
            }));
        });

        const passive_list = passiveList.flatMap((fund) => {
            return fund.funds.map((item) => ({
                "fund_id": item.fund_id,
                "basket_type": 'PASSIVE',
                "allocation_id": item.allocation_id,
                "allocation": parseFloat(fund.allocation),
                "asset_class": fund.asset_class,
                "primary_fund": item.primary_fund
            }));
        });

        const request = {
            "funds": {
                active: active_list,
                passive: passive_funds_exist.length === 0 ? null : passive_list
            }
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                navigate("/manage-portfolio", {
                    state: {
                        index: 2,
                        tab_index: 2,
                        basket: location.state.basket
                    }
                });
                toast.success('Portfolio updated', {
                    type: 'success'
                })
            } else {

                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })

    }

    const FUND_LIST = basketType === 1 ? fundList : passiveList

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage custom portfolios"
                        subTitle={`${location.state.portfolio !== null ? "Update" : "Create"} custom portfolio`} />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="e-bg-white border-radius-32px padding-40px-tb padding-32px-lr d-flex flex-column">

                    <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-4">{location.state.portfolio !== null ? "Update" : "Create"} Custom Portfolio</h6>
                    {/* <div className="col-12 mb-4">
                        <CustomTabBar data={BASKET_OPTIONS}
                            selectedTab={basketType}
                            onSelectTab={_handleBasket} />
                    </div> */}

                    <div className="e-bg-snowy-lavender border-radius-16px py-4 padding-32px-lr" ref={basketRef}>
                        {

                            FUND_LIST.map((item, index) => {
                                return (
                                    <div className="border-bottom-1px border-color-mint-gray py-3">
                                        <div className={index > 0 ? "row align-items-center" : "row align-items-center mb-4 pb-1"}>
                                            <div className="col-lg-4 col-md-6">
                                                <CustomTextInput1
                                                    label="Fund type"
                                                    placeHolder="Add fund type"
                                                    postFix={true}
                                                    value={item.asset_class}
                                                    error={item.error[0]}
                                                    handleChange={(input_value) => _handleFundType(input_value, index, 0)} />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <PercentageInput
                                                    postfix="*"
                                                    error={item.error[1]}
                                                    placeHolder="Allocation"
                                                    label="Allocation"
                                                    value={item.allocation}
                                                    handleChange={(input_value) => _handleAllocation(input_value, index, 1)} />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                {
                                                    FUND_LIST.length > 1 && basketType === 1 ? <LinkButton text="Delete fund type" className="ms-auto"
                                                        handleClick={() => _handleDeleteFund(index, 0, false)} />
                                                        :
                                                        basketType === 2 ?
                                                            <LinkButton text="Delete fund type" className="ms-auto"
                                                                handleClick={() => _handleDeleteFund(index, 0, false)} />
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                        {
                                            item.funds.map((fund, key) => {
                                                return (
                                                    <div className="row mt-4 d-flex align-items-center">
                                                        <div className="col-lg-4 col-md-6 ">
                                                            <p className={`e-montserrat-medium e-font-14 e-line-height-22 margin-6px-bottom color-dark-charcoal`}>
                                                                Add fund
                                                                <span className='color-deep-carmine-pink'>{"*"}</span>
                                                            </p>
                                                            <SearchSelect data={funds}
                                                                value={fund.fund}
                                                                error={fund.error[0]}
                                                                selectedFund={fund.fund_id}
                                                                selectFund={(fund) => _handleFundValue(fund.name, index, 0, key, fund.id)}
                                                                onFocus={() => _getFundList(null)}
                                                                valueChange={(input_value) => {
                                                                    _getFundList(input_value === "" ? null : input_value);
                                                                    _handleFundValue(input_value, index, 0, key, null);
                                                                }} />
                                                        </div>

                                                        <div className="col-lg-3 col-md-11 pt-4 ">
                                                            <CustomCheckBox label="Set as primary fund"
                                                                checked={fund.primary_fund}
                                                                onChange={(check) => _handlePrimaryFund(check, index, key)} />
                                                        </div>
                                                        <div className="col-lg-1 col-1 pt-3">
                                                            {
                                                                item.funds.length > 1 ? <Icon icon="delete"
                                                                    size={24}
                                                                    className="cursor-pointer e-delete-hover"
                                                                    // color={key === onDeleteHover && "#f6172d"}
                                                                    // onMouseEnter={() => _handleDeleteHover(key)}
                                                                    // onMouseLeave={() => _handleDeleteHover(null)}
                                                                    onClick={() => _handleDeleteFund(index, key, true)} />
                                                                    :
                                                                    FUND_LIST.length > 1 ? <Icon icon="delete"
                                                                        size={24}
                                                                        className="cursor-pointer e-delete-hover"
                                                                        // color={key === onDeleteHover && "#f6172d"}
                                                                        // onMouseEnter={() => _handleDeleteHover(key)}
                                                                        // onMouseLeave={() => _handleDeleteHover(null)}
                                                                        onClick={() => _handleDeleteFund(index, key, false)} />
                                                                        :
                                                                        null}
                                                        </div>
                                                        {/* {key === item.funds.length - 1 && <div className="col-lg-4 pt-4">
                                                            <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px padding-12px-tb d-flex align-items-center w-max-content cursor-pointer"
                                                                onClick={() => _handleAddSecondaryFund(index)}>
                                                                <Icon icon="add"
                                                                    size={24} />Add secondary fund</div>
                                                        </div>} */}

                                                    </div>
                                                )
                                            })
                                        }
                                        {<div className="col-12 pt-3">
                                            <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px padding-12px-tb d-flex align-items-center w-max-content cursor-pointer"
                                                onClick={() => _handleAddSecondaryFund(index)}>
                                                <Icon icon="add"
                                                    size={24} />Add secondary fund</div>
                                        </div>}

                                    </div>
                                )
                            })
                        }
                        {<div className="col-12 pb-3">
                            <PrimaryButton label="Add new fund type"
                                className="padding-14px-tb px-4 bg-white color-rebecca-purple border-rebecca-purple margin-32px-top"
                                handleClick={_handleAddFund} />
                        </div>}


                    </div>


                    <div className="d-flex justify-content-start gap-8px padding-120px-top">
                        <SecondaryButton label="Cancel"
                            className="padding-14px-tb padding-50px-lr"
                            handleClick={() => navigate("/manage-portfolio", {
                                state: {
                                    index: 2,
                                    tab_index: 2,
                                    basket: location.state.basket
                                }
                            })} />
                        <PrimaryButton label={location.state.portfolio !== null ? "Update" : "Create"}
                            loader={apiLoader}
                            disabled={apiLoader}
                            className="padding-14px-tb padding-50px-lr"
                            handleClick={_handleCreatePortfolio} />
                    </div>
                </div>
            </div>
            {/* {
                confirmFunds === true && <DeleteConfirmationModal delete={_confirm}
                    description="Since there are no passive funds available in suggested combination, kindly proceed with active funds. Passive funds shall be made available when we get such desired allocation"
                    label="Confirm"
                    close={() => {
                        _handleConfirmation(false)
                    }}
                    updateList={() => { _createCustomPortfolio(false) }} />
            } */}
        </Fragment>
    )
}

export default ManageCustomPortfolio