/*
 *   File : pyramid-details.js
 *   Author : https://evoqins.com
 *   Description : Pyramid details
 *   Version : 1.0.0
*/

import { Fragment } from "react"
import { EmptyScreen } from "../../../../Components/Others"


const PyramidDetails = (props) => {
    return (
        <Fragment>
            <div className="border-radius-32px bg-white padding-70px-bottom">
                <div className="padding-32px-all">
                    <div className="row">
                        <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0">Pyramid Details</h4>
                        {
                            props.is_updated ?
                                <div className="col-md-8  pb-4">
                                    <div className="e-bg-lavender-mist border-radius-24px e-box-shadow-black position-relative mt-4">

                                        <div className="d-flex justify-content-between align-items-center px-sm-4 px-3 pt-sm-4 pt-3  pb-sm-3 pb-2  border-bottom-1px border-color-mint-gray">
                                            <div className=" d-flex align-items-center gap-8px">
                                                <img src={require("../../../../Assets/Images/Funds/strength.png")}
                                                    alt="Strength"
                                                    width={36}
                                                    height={36}
                                                    draggable={false} />
                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0">Your financial strength: <span className="e-montserrat-medium color-vivid-gamboge" style={{ color: props.pyramid.score_color }}>{props.pyramid.score}</span></p>
                                            </div>

                                        </div>
                                        <div className="mt-3 px-sm-4 px-3 pb-sm-4 pb-3">

                                            {
                                                Object.entries(props.pyramid.data).map(([key, summary], index) =>
                                                    <div className={`py-sm-3 py-2 d-flex align-items-center justify-content-between ${index > 0 && "border-top-1px border-color-mint-gray"}`}
                                                        key={key}>
                                                        <div className="d-flex align-items-center gap-8px">
                                                            <img src={summary.image}
                                                                alt={summary.title}
                                                                draggable={false}
                                                                width={28}
                                                                height={28} />
                                                            <p className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black mb-0">{key}</p>
                                                        </div>
                                                        <span className="e-montserrat-medium e-font-16 e-line-height-24" style={{ color: summary.score_color }}>{summary.score}</span>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                    {
                                        Object.entries(props.pyramid.data).map(([key, summary], index) =>
                                            <div className="border-radius-24px e-box-shadow-white position-relative mt-4" style={{ backgroundColor: summary.color }}>
                                                <div className={`py-sm-3 py-2`}
                                                    key={key}>
                                                    <div className="d-flex align-items-center gap-8px px-sm-4 px-3">
                                                        <img src={summary.image}
                                                            alt={summary.title}
                                                            draggable={false}
                                                            width={28}
                                                            height={28} />
                                                        <p className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black mb-0 ">{key}</p>
                                                    </div>
                                                    {
                                                        summary.status === false ?
                                                            <div className="py-3 px-sm-4 px-3 e-font-16 e-line-height-24 e-montserrat-medium color-eerie-black text-start">
                                                                We prioritize your financial well-being by focusing on secure and stable growth, also avoiding high-risk speculation.
                                                            </div>
                                                            :
                                                            <div className="pt-3 ">
                                                                <h6 className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0 px-sm-4 px-3">Current value</h6>
                                                                {
                                                                    summary.data.map((item, fund_index) => {
                                                                        return (
                                                                            <div className={`py-sm-3 px-sm-4 px-3 py-2 my-1 z-index-3 position-relative ${fund_index > 0 ? "border-top-1px border-color-mint-gray" : ""}`}>
                                                                                <div className="row justify-content-between align-items-center">
                                                                                    <div className="col-12">
                                                                                        <div className="d-flex  align-items-center justify-content-between">
                                                                                            {/* Left side: Icon and name */}
                                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                                <img src={item.image} draggable={false} width={28} alt={key}
                                                                                                    className="h-28px w-28px" />
                                                                                                <p className="e-font-16 e-line-height-24 e-montserrat-medium mb-0">
                                                                                                    {item.name}:
                                                                                                </p>
                                                                                            </div>
                                                                                            {/* Right side: Value */}
                                                                                            <span className="e-montserrat-semi-bold e-font-14 e-line-height-20">{item.value_text}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                <EmptyScreen title="Pyramid not updated yet" />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PyramidDetails